<template>
	<div>
		<div class="form-group">
            <label for="cmep">{{ $t('monte.cmep') }} <span v-if="required">*</span></label>
            <div v-if="!countries_loaded">
			    <LoadingSpinner class="col-12" />
		    </div>

            <div v-if="(type_monte == 'iaf' || type_monte == 'mm' || type_monte == 'mn') && cmep_model">
                {{ cmep_model.tiers_rs }} 
                <span v-if="cmep_model.tiers_address1">
                    - {{ cmep_model.tiers_address1 | multiCapitalize }} 
                    <span v-if="cmep_model.tiers_address2">{{ cmep_model.tiers_address2 | multiCapitalize }} </span>
                    <span v-if="cmep_model.tiers_postalcode && cmep_model.tiers_postalcode > 0">, {{ cmep_model.tiers_postalcode }} </span>
                    <span v-if="cmep_model.tiers_town">{{ cmep_model.tiers_town | multiCapitalize }} </span>
                    <span v-if="cmep_model.tiers_pays">, {{ cmep_model.tiers_pays | uppercase }}</span>
                </span>
            </div>
            <template v-else>
                <CountryInput v-if="countries_loaded && !disabled" v-model="cmep_country" :restricted_table="countries"></CountryInput>

                <e-select
                    v-if="cmep_country == 75 && countries_loaded && !disabled"
                    v-model="cmep_postalcode"
                    :placeholder="labelTitlePostalCodeCmep"
                    :options="postal_codes"
                >
                </e-select>

                <br><a v-if="most_used_cmep && !disabled" style="color:#b28b58" @click="loadCmep()">{{ $t("tiers.cmep_want_to") }} {{ most_used_cmep.tiers_rs }} ?</a>
                <e-select
                    v-if="countries_loaded"
                    v-model="cmep_model"
                    track-by="tiers_id"
                    label="tiers_rs"
                    :placeholder="labelTitleCmep"
                    :selectedLabel="selectedLabelCmep"
                    :options="current_tiers_tab"
                    :allow-empty="true"
                    :show-labels="false"
                    :disabled="disabled"
                    :sortable="false"
                    :loading="loadingTiers"
                    @search-change="asyncFind"
                    :internal-search="false"
                    :class="{ 'is-invalid': errors && errors.indexOf('cmep') > -1 }"
                >
                    <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.tiers_rs }} 
                        <span v-if="option.tiers_address1">
                            - {{ option.tiers_address1 | multiCapitalize }} 
                            <span v-if="option.tiers_address2">{{ option.tiers_address2 | multiCapitalize }} </span>
                            <span v-if="option.tiers_postalcode && option.tiers_postalcode > 0">, {{ option.tiers_postalcode }} </span>
                            <span v-if="option.tiers_town">{{ option.tiers_town | multiCapitalize }} </span>
                            <span v-if="option.tiers_pays">, {{ option.tiers_pays | uppercase }}</span>
                        </span>
                    </template>
                    
                    <template slot="option" slot-scope="{ option }">
                        {{ option.tiers_rs }} 
                        <span v-if="option.tiers_address1">
                            - {{ option.tiers_address1 | multiCapitalize }} 
                            <span v-if="option.tiers_address2">{{ option.tiers_address2 | multiCapitalize }} </span>
                            <span v-if="option.tiers_postalcode && option.tiers_postalcode > 0">, {{ option.tiers_postalcode }} </span>
                            <span v-if="option.tiers_town">{{ option.tiers_town | multiCapitalize }} </span>
                            <span v-if="option.tiers_pays">, {{ option.tiers_pays | uppercase }}</span>
                        </span>
                    </template>
                </e-select>
            </template>
        </div>
	</div>
</template>

<script type="text/javascript">
    import _sortBy from 'lodash/sortBy'
    import _uniq from 'lodash/uniq'
    import TiersMixin from "@/mixins/Tiers.js"
    import ContractMixin from "@/mixins/Contract.js"
	
    export default {
		name: 'ChangementCMEP',
		mixins: [TiersMixin, ContractMixin],
		props: {
			contract_id: { type: Number, default: 0 },
			tiers_tab: { type: Array, default: () => [] },
			disabled: { type: Boolean, default: false },
			cmep: { type: Object, default: null },
            required: { type: Boolean, default: true },
            type_monte: { type: String, default: '' }
        },
		data () {
			return {
				cmep_model: null,
                cmep_country: 75,
				current_tiers_tab: [],
				saved_elements: null,
				loadingTiers: false,
				errors: [],
                cmep_postalcode: null,
                postal_codes: [],
                countries: [],
                countries_loaded: false,
                labelTitlePostalCodeCmep: this.getTrad("monte.rechercher_postalcode_cmep"),
                most_used_cmep: null,
				labelTitleCmep: this.getTrad("monte.rechercher_cmep"),
				selectedLabelCmep: this.getTrad("global.selected_label"),
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
                this.postal_codes = await this.loadPostalCodesCmep()
                this.postal_codes = Object.values(this.postal_codes[0])
                this.postal_codes = _uniq(this.postal_codes)
                this.postal_codes = this.postal_codes.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0))

                this.countries = await this.loadCountriesCmep()
                this.countries = Object.values(this.countries[0])
                this.countries = _uniq(this.countries)

                this.countries_loaded = true

				this.cmep_model = this.cmep
                this.current_tiers_tab = this.tiers_tab
                this.current_tiers_tab = _sortBy(this.current_tiers_tab, ['tiers_rs'])

                const most_used_cmep = await this.getMostUsedCmep()
                if(most_used_cmep) {
				    this.most_used_cmep = this.current_tiers_tab.find(t => t.tiers_id == most_used_cmep)
                }
			},
            asyncFind (query) {
                if(this.saved_elements === null){
                    this.saved_elements = this.deppCloneObj(this.tiers_tab)
                }

                if(query == ""){
                    this.current_tiers_tab = this.saved_elements
                    return true
                }

                this.current_tiers_tab = []

                let current_elements = this.deppCloneObj(this.saved_elements)
                let tab_elems = []

                tab_elems = current_elements.filter((elem, key) => {
                    if(elem.tiers_address2 == null) {
                        elem.tiers_address2 = ""
                    }
                    if(elem.tiers_address1 == null) {
                        elem.tiers_address1 = ""
                    }
                    if(elem.tiers_postalcode == null) {
                        elem.tiers_postalcode = ""
                    }
                    if(elem.tiers_town == null) {
                        elem.tiers_town = ""
                    }
                    if(elem.tiers_pays == null) {
                        elem.tiers_pays = ""
                    }

                    if(elem.tiers_rs.toLowerCase().indexOf(query.toLowerCase()) > -1 || 
                    elem.tiers_address1.toLowerCase().indexOf(query.toLowerCase()) > -1 || 
                    elem.tiers_address2.toLowerCase().indexOf(query.toLowerCase()) > -1 || 
                    elem.tiers_postalcode.toLowerCase().indexOf(query.toLowerCase()) > -1 || 
                    elem.tiers_town.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
                    elem.tiers_pays.toLowerCase().indexOf(query.toLowerCase()) > -1) {

                        return true
                    }

                    return false
                })

                tab_elems = _sortBy(tab_elems, ['tiers_rs'])

                this.current_tiers_tab = tab_elems
            },
            loadCmep() {
                this.cmep_model = this.most_used_cmep
            }
		},
		watch: {
			cmep_model(val){
				this.$emit("update:cmep", val)
			},
            async cmep_country(val) {
                this.cmep_model = null
                this.cmep_postalcode = null

                let selected_pc = 0
                if(val == 75 && this.cmep_postalcode) {
                    selected_pc = parseInt(this.cmep_postalcode.substring(0,2))
                }
                else if(selected_pc !== 0 && typeof val === 'string') {
                    selected_pc = parseInt(selected_pc.substring(0,2))
                }

                this.current_tiers_tab = await this.loadCmepTiersWithCountry(val, selected_pc)
                this.current_tiers_tab = _sortBy(this.current_tiers_tab, ['tiers_rs'])
            },
            async cmep_postalcode(val) {
                this.cmep_model = null
                if(this.cmep_country == 75) {
                    let pc = val
                    if(val && typeof val === 'string') {
                        pc = parseInt(val.substring(0,2))
                        if(pc < 10) {
                            pc = pc.toString().padStart(2, '0')
                        }
                    }

                    this.current_tiers_tab = await this.loadCmepTiersWithCountry(this.cmep_country, pc)
                    this.current_tiers_tab = _sortBy(this.current_tiers_tab, ['tiers_rs'])
                }
            },
            cmep(val) {
                if(val) {
                    this.cmep_model = val
                }
            }
		},
        components: {
            CountryInput : () => import('GroomyRoot/components/Inputs/CountryInput'),
            LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
        }
	}

</script>